/*
 * @Author       : Hugo
 * @Date         : 2020-07-27 13:50:08
 * @LastEditors  : Hugo
 * @LastEditTime : 2021-12-27 12:25:34
 * @Description  :
 * @FilePath     : /miaohang/src/router/fun_template.js
 */

import shopping_mail from './modules/shopping_mail';
const fun_template = [
  {
    path: "/fun_template",
    name: "fun_template",
    component: () => import('@/views/layout/default.vue'),
    meta: {allowBack: false},
    // redirect: 'fun_task_index',
    children: [
      {
        path: "index",
        name: "fun_template_index",
        components: {
          default: ()=> import('@/views/fun_template/index.vue'),
          sidebar: () => import('@/components/Group/Aside/Template.vue'),
        },
        meta: {allowBack: false},
      },
      {
        path: 'my/:type',
        name: 'fun_template_my',
        components:{
          default: ()=> import('@/views/fun_template/My.vue'),
          sidebar: () => import('@/components/Group/Aside/Template.vue'),
        },
        meta: {allowBack: false},
      },
      {
        path: 'my/:type/folder/:folderid',
        name: 'fun_template_my_custom',
        components:{
          default: ()=> import('@/views/fun_template/My.vue'),
          sidebar: () => import('@/components/Group/Aside/Template.vue'),
        },
        meta: {allowBack: false},
      },
      {
        path: 'draft/:type',
        name: 'fun_template_draft',
        components:{
          default: ()=> import('@/views/fun_template/Draft.vue'),
          sidebar: () => import('@/components/Group/Aside/Template.vue'),
        },
        meta: {allowBack: false},
      },

      {
        path: 'recycle_bin',
        name: 'fun_template_recycle_bin',
        components:{
          default: ()=> import('@/views/fun_template/RecycleBin.vue'),
          sidebar: () => import('@/components/Group/Aside/Template.vue'),
        },
        meta: {allowBack: false},
      },
      {
        path: 'shopping_mail',
        name: 'fun_template_shopping_mail',
        components:{
          default: ()=> import('@/views/fun_template/ShoppingMail.vue'),
          sidebar: () => import('@/components/Group/Aside/Template.vue'),
        },
        meta: {allowBack: false},
        children: shopping_mail,
        redirect: {name: 'shopping_mail'} // 重定向
      },


      // // 会话
      // {
      //   path: 'dialog',
      //   name: 'fun_task_dialog',
      //   components: {
      //     default: ()=> import('@/views/layout/dialog.vue'),
      //     sidebar: () => import('@/components/Group/Aside/Task.vue'),
      //   },
      //   children: [
      //     {
      //       path: 'single/:id',
      //       name: 'fun_task_dialog_single',
      //       component: ()=> import('@/views/fun_task/dialog.vue'),
      //     },
      //     {
      //       path: 'group/:id',
      //       name: 'fun_task_dialog_group',
      //       component: ()=> import('@/views/fun_task/dialog.vue'),
      //     }

      //   ]
      // }

    ]
  }
]

export default fun_template;
